<template>
  <div>
    <div class="row w-100 m-auto">
      <div class="col-12 p-0">
        <div class="d-flex align-items-center justify-content-between report">
          <div class="d-flex align-items-center">
            <img
              src="@/assets/icons/icon-draggable.svg"
              alt="Draggable"
              class="cursor-pointer"
              content="Draggable"
              v-tippy="{ placement: 'bottom-center' }"
            />
            <span class="ms-3">
              {{ reportList.name }}
            </span>
          </div>
          <div class="d-flex align-items-center">
            <div
              class="favorite-toggle cursor-pointer"
              @click="$emit('favorite', reportList.id)"
            >
              <img
                src="@/assets/icons/icon-fav.svg"
                alt="Priority"
                v-if="reportList.favorite === false"
              />
              <img
                src="@/assets/icons/icon-fav blue active.svg"
                alt="Priority"
                v-else
              />
            </div>
            <div
              class="btn btn-control ms-1"
              :name="`report-menu-${listName}-${reportList.id}`"
            >
              <img
                src="@/assets/icons/icon-more.svg"
                alt="More button"
                class="icon-more"
              />
            </div>
            <tippy
              boundary="window"
              interactive="true"
              trigger="click"
              class="table-menu"
              zIndex="1039"
              animateFill="false"
              distance="2"
              placement="bottom-end"
              theme="light"
              :to="`report-menu-${listName}-${reportList.id}`"
            >
              <div class="table-menu-block">
                <ul>
                  <li class="action-label">Actions</li>
                  <li class="cursor-pointer">
                    <a @click="$emit('renameModal', reportList)">
                      <img src="@/assets/icons/icon-rename.svg" alt="Rename" />
                      Rename
                    </a>
                  </li>
                  <li class="cursor-pointer">
                    <router-link
                      :to="{
                        name: 'edit_report',
                        params: { id: reportList.id },
                      }"
                    >
                      <a :to="{ name: 'edit_report' }">
                        <img
                          src="@/assets/icons/icon-edit-normal.svg"
                          alt="Edit Report"
                        />
                        Edit Report
                      </a>
                    </router-link>
                  </li>
                  <li
                    class="cursor-pointer"
                    @click="$emit('delete', reportList.id)"
                  >
                    <a>
                      <img src="@/assets/icons/icon-delete.svg" alt="" />
                      Delete
                    </a>
                  </li>
                </ul>
              </div>
            </tippy>
          </div>
        </div>
      </div>
    </div> 
  </div>
</template>

<script>
export default {
  name: "ReportList",
  props: {
    report: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      reportList: this.report,
      listName: this.name
    };
  },
};
</script>

<style lang="scss" scoped>
.report {
  max-width: 715px;
  height: 50px;
  width: 100%;
  border-bottom: 1px solid #e7e8e8;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}
</style>
