<template>
  <div class="page-content-grey">
    <div class="page-header d-flex align-items-center justify-content-between">
      <h3 class="page-header-title">
        Reports
      </h3>
      <router-link :to="{ name: 'new_report' }">
        <button class="btn btn-outline-dark">
          Create Report
        </button>
      </router-link>
    </div>
    <div class="page-content p-normal">
      <div class="text-center mt-4" v-if="ui.loading === true">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="row reports-list" v-else>
        <div class="col-12 mb-3">
          <div class="reports-list_item">
            <div class="item-header">
              Favorites
            </div>
            <div v-if="favoriteList.length === 0" class="item-body">
              No Report found
            </div>
            <div v-else>
              <div
                class="item-body"
                v-for="report in favoriteList"
                :key="report.id">
                <report-list 
                  :report="report"
                  :name="'favorites'"
                  @delete="deleteReport"
                  @favorite="toggleFavorite"
                  @renameModal="openRenameModal"></report-list>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-3">
          <div class="reports-list_item">
            <div class="item-header">
              Company Reports
            </div>
            <div v-if="companyList.length === 0" class="item-body">
              No Report found
            </div>
            <div v-else>
              <div
                class="item-body"
                v-for="report in companyList"
                :key="report.id">
                <report-list 
                  :report="report"
                  :name="'company'"
                  @delete="deleteReport"
                  @favorite="toggleFavorite"
                  @renameModal="openRenameModal"></report-list>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-3">
          <div class="reports-list_item">
            <div class="item-header">
              Agent Reports
            </div>
            <div v-if="agentList.length === 0" class="item-body">
              No Report found
            </div>
            <div v-else>
              <div
                class="item-body"
                v-for="report in agentList"
                :key="report.id">
                <report-list 
                  :report="report"
                  :name="'agent'"
                  @delete="deleteReport"
                  @favorite="toggleFavorite"
                  @renameModal="openRenameModal"></report-list>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="reports-list_item">
            <div class="item-header">
              Processor Reports
            </div>
            <div v-if="processorList.length === 0" class="item-body">
              No Report found
            </div>
            <div v-else>
              <div
                class="item-body"
                v-for="report in processorList"
                :key="report.id">
                <report-list 
                  :report="report"
                  :name="'processor'"
                  @delete="deleteReport"
                  @favorite="toggleFavorite"
                  @renameModal="openRenameModal"></report-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-page-transition name="fade">
      <report-rename-modal
        v-if="modals.renameReport"
        @close="closeRenameModal"
        @save="saveName"
        :report="selectedReport"
      />
    </vue-page-transition>  
  </div>
</template>

<script>
import ReportRenameModal from "./modals/ReportRenameModal";
import ReportList from "./includes/ReportList";
export default {
  name: "Reports",
  components: {ReportRenameModal, ReportList },
  data() {
    return {      
      modals: {
        renameReport: false
      },
      report: {
        loading: false,
        error: false,
        errorMessage: "",
      },
      reportList: [],
      ui: {
        loading: false,
        rename: false
      },
      selectedReport: null
    };
  },
  computed: {
    favoriteList() {
      return this.reportList.filter(el => el.favorite);
    },
    agentList() {
      return this.reportList.filter(el => el.type === 'agent');
    },
    companyList() {
      return this.reportList.filter(el => el.type === 'company');
    },
    processorList() {
      return this.reportList.filter(el => el.type === 'processor');
    }
  },
  methods: {
    toggleFavorite(id) {
      let reportList = this.reportList.find(report => report.id === id);
      if (reportList) {
        reportList.favorite = !reportList.favorite;
      }
      this.$http
        .get(`/api/v1/reports/${id}/toggle/favorite`)
        .then(res => {
          reportList.favorite = res.data.data.favorite;
       })
        .catch(err => {
          this.report.error = true;
          this.report.errorMessage = err.response.data.error;
        });
    },
    getData() {
      this.ui.loading = true;
      this.$http
        .get("/api/v1/reports/")
        .then(res => {
          this.ui.loading = false;
          this.reportList = res.data;
        })
        .catch(err => {
          this.report.error = true;
          this.report.errorMessage = err.response.data.error;
        });
    },
    deleteReport(id) {
      if (confirm("Are you sure?") === true) {
        this.ui.loading = true;
        this.$http
          .get(`/api/v1/reports/${id}/delete`)
          .then(() => {
            this.ui.loading = false;
              const filteredReportList = this.reportList.filter(report => report.id !== id)
              this.reportList = filteredReportList
          })
          .catch(err => {
            this.report.error = true;
            this.report.errorMessage = err.response.data.error;
          });
      }
    },
    closeRenameModal() {
      this.modals.renameReport = false
      this.selectedReport = null
    },
    openRenameModal(val) {
      this.modals.renameReport = true
      this.selectedReport = val
    },
    saveName(val) {
      this.reportList.map((el) => {
        if (el.id === this.selectedReport.id) {
          el.name = val
        }
        return el;
      });
      this.closeRenameModal();
    }
  },
  created() {
    this.getData();
  }
};
</script>

<style lang="scss" scoped>
.reports-list {
  &_item {
    background: #ffffff;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    .item-header {
      color: #000000;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 30px;
      border-bottom: 1px solid #e7e8e8;
      padding: 15px 24px;
      border-radius: 4px;
    }
    .item-body {
      padding: 24px 24px 30px 24px;
    }
  }
}
</style>
